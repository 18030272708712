.sub__menu:hover .collapse {
  display: block;
}

.show__sider {
  flex: 0 0 300px;
  max-width: 300px;
  min-width: 300px;
  width: 300px;
}

.hide__sider {
  flex: 0 0 88px;
  max-width: 88px;
  min-width: 88px;
  width: 88px;
}

.__scroll {
  height: calc(100vh - 70px);
}

.__scroll::-webkit-scrollbar {
  width: 6px;
}

.__scroll::-webkit-scrollbar-track {
  background-color: #3f4555;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.__scroll::-webkit-scrollbar-thumb {
  background-color: #596177;
  border-radius: 8px;
}


.sub__menu ,.sub__menu_2 {
  position: relative;
}

.hover-selection {
  display: none; 
  position: absolute; 
  top: -10px;
  left: 75px;
  /* width: 100px; */
  padding: 10px 20px;
  border-radius: 20px;
  /* overflow: hidden; */
  z-index: 1000;
  transition: visibility 0s, opacity 0.3s linear;
}
.hover-selection1 {
  display: none; 
  position: absolute; 
  top: -10px;
  left: 96%;
  padding: 10px;
  border-radius: 20px;
  overflow: hidden;
  z-index: 1000;
  transition: visibility 0s, opacity 0.3s linear;
}
.hover-selection:hover {
  visibility: visible;
  opacity: 1;
}

.sub__menu:hover .hover-selection {
  display: block; 
}
.sub__menu_2:hover .hover-selection1 {
  display: block; 
}