/* Set default font-family */
.ql-container {
  background-color: white;
}

.app .ql-editor {
  font-family: "Lato";
}

/* Set dropdown font-families */
.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
  font-family: "Lato", cursive;
  content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", cursive;
  content: "Roboto" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Noto-Sans-JP"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Noto-Sans-JP"]::before {
  font-family: "Noto Sans JP", cursive;
  content: "Noto Sans JP" !important;
}

/* Set content font-families */
.ql-font-Lato {
  font-family: "Lato" !important;
}

.ql-font-Roboto {
  font-family: "Roboto" !important;
}

.ql-font-Noto-Sans-JP {
  font-family: "Noto Sans JP" !important;
}

.ql-tooltip {
  left: 0 !important;
}

.ql-snow {
  .ql-picker {
    .ql-picker-options {
      overflow: auto;
      height: 300px;
    }

    &.ql-size {

      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}

body {
  font-family:
    Lato,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto;
  font-size: 16px;
}

.ant-modal-footer {
  .ant-btn {
    min-width: 128px;
  }
}

.btn-create {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;  
}

.btn-save {
  min-width: 128px;
}

.bg_layout_content {
  background-color: #f8fafd;
}

.ant-notification-notice {
  padding: 16px !important;
  width: 100% !important;
}

.ant-notification-notice-close {
  top: 15px !important;
  inset-inline-end: 10px !important;
}

.ant-notification-notice-message {
  margin-bottom: 0 !important;
}

.ant-notification-notice-wrapper {
  width: calc(100vw - 720px) !important;
  border-radius: 2px !important;
  margin-right: 400px;
}

.ant-notification-topRight {
  right: -24px !important;
}

.ant-notification-notice-warning {
  background-color: #fffbe6;
  border: #ffe58f !important;
}

.ant-notification-notice-success {
  background-color: #f6ffed;
  border: #b7eb8f !important;
}

.ant-notification-notice-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7 !important;
}

.shadow_antd .ant-btn {
  justify-content: left;
  text-align: start;
}

// .ant-form-item-label {
//   color: #323842;
//   font-weight: 700;
// }
.ant-modal-content .ant-modal-header {
  border-bottom: 1px solid #e6edf2 !important;
  padding-bottom: 16px !important;
}

.ant-modal-title {
  font-size: 20px !important;
}

.address-input input,
.address-input .ant-select {
  margin-bottom: 15px !important;
}

.modal_confirm {
  .ant-modal-content {
    padding: 0 !important;

    .ant-modal-confirm-body {
      padding: 16px;

      .ant-modal-confirm-paragraph {
        max-width: 100%;
      }
    }

    .ant-modal-confirm-btns {
      padding: 16px;
      text-align: center;

      .ant-btn {
        width: 45%;
      }

      .ant-btn-primary {
        background-color: #f87171;

        &:hover {
          background-color: #f87171;
        }
      }
    }
  }
}

.modal_confirm_success {
  .ant-modal-content {
    padding: 0 !important;

    .ant-modal-confirm-body {
      padding: 16px;

      .ant-modal-confirm-paragraph {
        max-width: 100%;
      }
    }

    .ant-modal-confirm-btns {
      padding: 16px;
      text-align: center;

      .ant-btn {
        width: 45%;
      }
    }
  }
}

.ant-select-dropdown .ant-select-item {
  padding: 8px 12px !important;
}

.ant-input-status-error {
  border-color: #ff4d4f !important;
}

.ant-form-item-explain {
  margin-top: 3px;

  .ant-form-item-explain-error {
    background-image: url("../src/assets/images/close-circle.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: left center;
    padding-left: 20px;
    padding-top: 0px;
    font-size: 12px;
    font-weight: 400;
    color: #646970 !important;
  }
}

.collapse__detail {
  .ant-collapse-header {
    padding: 0px 16px !important;
  }
}

.ant-collapse-header-text {
  margin-inline-end: unset !important;
  flex: none !important;
}

.report-item {
  box-shadow:
    0px 2px 4px 0px #00000005,
    0px 1px 6px -1px #00000005,
    0px 1px 2px 0px #00000008,
    0px -1px 4px 0px #00000008;

  .ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4dabd1;
    border-color: #4dabd1;
  }
}

.ant-btn-color-dangerous {
  &.ant-btn-variant-solid {
    background-color: #f87171 !important;
  }
}

.ant-steps {
  .ant-steps-item-process {
    .ant-steps-item-icon {
      background-color: #4dabd1 !important;
      border: 0 !important;
    }
  }
}

.ant-steps {
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #e3f1f8 !important;
    border: 0 !important;
  }
}

.ant-steps {
  .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #e6edf2 !important;
  }
}

.ant-select-outlined.ant-select-multiple {
  .ant-select-selection-item {
    background-color: #e3f1f8 !important;
    color: #1c3e4b !important;
    font-size: 14px !important;
  }
}

.ant-select-multiple {
  .ant-select-selection-overflow {
    .ant-select-selection-item-remove {
      color: #1c3e4b !important;
      font-size: 14px !important;
    }
  }
}

.ant-form-vertical {
  .ant-form-item:not(.ant-form-item-horizontal) {
    .ant-form-item-label {
      padding-bottom: 4px !important;
    }
  }
}

.ant-modal {
  .ant-modal-header {
    background-color: #f8fafd !important;
  }
}

.ant-modal {
  .ant-modal-content {
    background-color: #f8fafd !important;
  }
}

// .ant-btn-variant-solid {
//   background-color: #4dabd1 !important;
// }

.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #4dabd1 !important;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #4dabd1 !important;
    border-color: #4dabd1 !important;
  }
}

.ant-picker-outlined:focus {
  border-color: #4dabd1 !important;
}

// button.ant-btn-variant-solid:not(disabled) {
//   background-color: #4dabd1 !important;
// }

button.ant-btn-variant-solid:disabled {
  background-color: #E1E8ED !important;
  border-color: transparent !important;
}

.ant-steps {
  .ant-steps-item-process {
    .ant-steps-item-icon {
      background-color: #4dabd1 !important;
      border-color: #4dabd1 !important;
    }
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #4dabd1 !important;
    border-color: #4dabd1 !important;
  }
}

.ant-checkbox-disabled {
  .ant-checkbox-inner {
    background-color: rgba(0, 0, 0, 0.04) !important;
    border-color: #c2c2c2 !important;
  }
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    &:after {
      background-color: #4dabd1 !important;
    }
  }
}

.box__phone {
  .ant-input-group-addon {
    background-color: white !important;
  }
}

.ant-tag {
  white-space: normal !important;
}

.alert-warning {
  padding: 8px 12px !important;
}

.ant-table-expanded-row-fixed {
  background-color: #F5F8FA;
}

.ant-steps-item {
  .ant-steps-item-tail::after {
    background-color: #E6EDF2 !important;
  }
}
.ant-steps-item-wait {
  .ant-steps-item-icon {
    background-color: #E1E8ED !important;
  }
  .ant-steps-icon {
    color: #A2A2A2 !important;
  }
}
.ant-modal-close-icon {
  svg {
    width: 24px !important;
    height: 24px !important;
  }
}
.ant-select-clear {
  .anticon-close-circle {
    svg {
      color: #646970 !important;
    }
  }
}